.home-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1e1e1e;
    color: white;
  }
  
  .main {
    display: flex;
    flex: 1;
  }
  