.editor-window {
    flex: 1;
    padding: 10px;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #007acc;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005a99;
  }
  
  select {
    background-color: #2d2d2d;
    color: white;
    border: 1px solid #444;
    padding: 5px;
  }
  